import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'browse',
    pathMatch: 'full',
  },
  {
    path: 'browse/:id',
    loadChildren: () =>
      import('./pages/browse/browse.module').then((m) => m.BrowsePageModule),
  },
  {
    path: 'favourites',
    loadChildren: () =>
      import('./pages/favourites/favourites.module').then(
        (m) => m.FavouritesPageModule
      ),
  },
  {
    path: 'create',
    loadChildren: () =>
      import('./pages/create/create.module').then((m) => m.CreatePageModule),
  },
  {
    path: 'browse',
    loadChildren: () =>
      import('./pages/browse/browse.module').then((m) => m.BrowsePageModule),
  },
  {
    path: 'tb/:id/:step',
    loadChildren: () =>
      import('./pages/trainbit/trainbit.module').then(
        (m) => m.TrainbitPageModule
      ),
  },
  {
    path: 'tb/:id',
    loadChildren: () =>
      import('./pages/trainbit/trainbit.module').then(
        (m) => m.TrainbitPageModule
      ),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    public db: AngularFirestore,
    public auth: AngularFireAuth,
    public storage: AngularFireStorage
  ) {}

  getTrainbits() {
    return this.db.collection('trainbits').snapshotChanges();
  }

  getTrainbit(trainbitId) {
    return this.db.collection('trainbits').doc(trainbitId).snapshotChanges();
  }

  getTrainbitSteps(trainbitId) {
    return this.db
      .collection('trainbits')
      .doc(trainbitId)
      .collection('steps', (ref) => ref.orderBy('step'))
      .snapshotChanges();
  }

  getTrainbitStep(trainbitId, stepId) {
    return this.db
      .collection('trainbits')
      .doc(trainbitId)
      .collection('steps')
      .doc(stepId)
      .snapshotChanges();
  }

  // .collection('steps', (ref) => ref.where('step', '==', step))
  getTrainbitStepResources(trainbitId, stepId) {
    return this.db
      .collection('trainbits')
      .doc(trainbitId)
      .collection('steps')
      .doc(stepId)
      .collection('resources')
      .snapshotChanges();
  }
}

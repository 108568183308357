// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'project-7990924520370292100',
    appId: '1:937975221376:web:59af39a4ae92ea6aa62a65',
    databaseURL: 'https://project-7990924520370292100.firebaseio.com',
    storageBucket: 'project-7990924520370292100.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyB_IZWozJsaay9pBBPLO5NQNhVfN1LdPww',
    authDomain: 'project-7990924520370292100.firebaseapp.com',
    messagingSenderId: '937975221376',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
